import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  styled,
} from "@mui/material";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
  deleteDataFromApi,
  patchDataFromApi,
} from "../../Services/CommonServices";
import {
  eventworkflowAdd,
  vehicalGroupList,
  eventworkflowGet,
  eventworkflowUpdate,
  eventworkflowList,
  eventworkflowStepAdd,
  createWorkFlow,
  coachingcreateWorkFlow,
  eventworkflowStepList,
  driverGroupList,
  driverGroupGet,
  driverGroup,
  eventList,
  eventworkflowDelete,
  userGroup,
  eventDropDownList,
  eventDesigner,
  workflowStepListApi,
  companyManagementList,
  coachingworkflowStepList,
  userManagementList,
} from "../../Services/api";
import { Autocomplete } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseBtn from "../../Assets/images/close.png";
import AlertMessage from "../commoncomponent/AlertMessage";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const AutoComplete = styled(Autocomplete)(() => ({
  width: 300,
  marginBottom: "16px",
}));

const DialogForm = ({ mode, open, onClose, data, formData, handleSubmit }) => {
  const [formState, setFormState] = useState(formData);
  useEffect(() => {
    setFormState(formData);
  }, [formData]);

  const navigate = useNavigate();
  const [selected, setSelected] = useState("yes");
  let { eventid } = formData;
  const path = window.location.href.split("/");
  const veiwMode = mode === "view";
  const [addmoreEventOpen, addmoreEventsetOpen] = React.useState(false);
  const addmoreEventhandleOpen = () => addmoreEventsetOpen(true);
  const addmoreEventhandleClose = () => addmoreEventsetOpen(false);
  const [alert, setalert] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [alertType, setalertType] = useState("");
  const [samePage, setsamePage] = useState(false);
  const [workflowStepList, setworkflowStepList] = useState([]);
  const [addedSteps, setaddedSteps] = useState({ checkboxes: [] });
  const [getVehicleGroup, setVehicleGroup] = useState([]);
  const [DriverGroup, setDriverGroup] = useState([]);
  const [driverGroupOpt, setdriverGroupOpt] = useState([]);
  const [usergroups, setusergroups] = useState([]);
  const [usergroupsOpt, setusergroupsOpt] = useState([]);
  const [events, setevents] = useState([]);
  const [customSteps, setcustomSteps] = useState([]);
  const [selectedIndex, setselectedIndex] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [categoryopt, setCategoryOpt] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [editmore, seteditmore] = React.useState(false);
  const [editmoreId, seteditmoreId] = React.useState("");
  const [is_edit_loaded, set_is_edit_loaded] = useState(false);
  const [singleUser, setsingleUser] = useState([]);
  const [eventdropdown, setEventDropdown] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const userCompany = localStorage.getItem("userCompany");
  const [isViewEnable, setIsViewEnable] = useState(true);

  const userRole = localStorage.getItem("userRole");
  var user_role_opt = [];
  if (userRole?.toLowerCase() == "icam admin") {
    user_role_opt = [
      { id: "iCAM Admin", label: "iCAM Admin" },
      { id: "iCAM User", label: "iCAM User" },
      { id: "Company Admin", label: "Company Admin" },
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
    ];
  } else if (userRole?.toLowerCase() == "icam user") {
    user_role_opt = [
      { id: "Company Admin", label: "Company Admin" },
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
    ];
  } else if (userRole?.toLowerCase() == "company admin") {
    user_role_opt = [
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
    ];
  }

  const getworkFlowEvent = async () => {
    var loginId = localStorage.getItem("currentUser");
    // + loginId + "/"
    const response = await getDataFromApi(
      coachingworkflowStepList + "?id=" + loginId
    );
    if (response && response.status == 200 && response.data != null) {
      response.data.sort((a, b) => b.id - a.id);

      // setWorkFlowDetails(response.data);
    }
  };

  function confirm() {
    setalert(false);
    navigate("/coaching_eventDesigner");
    // addmoreEventsetOpen(false);
    onClose();
    // seteditmoreId("");
    // setDeleteOpen(false);
    // if (samePage) {

    getworkFlowEvent();
    // }
  }
  useEffect(() => {
    if (eventid) {
      getsingleUser();
    } else {
      set_is_edit_loaded(true);
    }
    // getworkflowStepList();
    getusergroup();
    
  }, []);

  useEffect(()=>{
    getSelectedItem()
  },[formState])

  const getusergroup = async () => {
    var response = "";
    response = await getDataFromApi(userManagementList);
    if (response && response.status == 200 && response.data != null) {
      setusergroups(response.data);
      var sd = response.data;
      var groupss = [];
      sd.map((opt) => {
        var jd = [];
        jd["id"] = opt.id;
        jd["label"] = opt.user_name;
        groupss.push(jd);
      });
      setusergroupsOpt(groupss);
    }
  };
 

  const getsingleUser = async () => {
    var response = "";
    response = await getDataFromApi(workflowStepListApi + eventid + "/");
    if (response && response.status == 200 && response.data != null) {
      setsingleUser(response.data);
      if (eventid) {
        var userdata = response.data;
        const multipleUsergroups = [];
        userdata.user_groups.map((allusergroups, i) => {
          multipleUsergroups.push(allusergroups.id);
        });
        setFormState((formData) => ({
          ...formData,
          ["event_step_name"]: userdata.event_step_name,
          ["description"]: userdata.description,
          ["step_coaching_users"]: multipleUsergroups,
          ["step_name"]: userdata.step_name,
          ["company_name"]: userdata.company_name,
          // ['pdf_required']: userdata.pdf_required ? "True" : "False",
          //       ["workflow_name"]: userdata.workflow_name,
          //       ["vehicle_group"]: userdata.vehicle_group
          //         ? userdata.vehicle_group.id
          //         : "",
          //       ["driver_group"]: userdata.driver_group
          //         ? userdata.driver_group.id
          //         : "",
          //       ["event_type"]: userdata.event_type,
          //       ["callto"]: userdata.callto,
          //       ["informdriver"]: userdata.informdriver,

          //       ["nameRadio"]: userdata.nameRadio,

          //       ["category"]: userdata.category.toUpperCase(),

          //       // ["default"]: userdata.default,
        }));
        userdata.workflow_steps &&
          userdata.workflow_steps.map((opt) => {
            customSteps.push(opt);
          });
        setaddedSteps((chk) => ({
          ...chk,
          checkboxes: userdata.workflow_steps,
        }));

        setTimeout(() => {
          set_is_edit_loaded(true);
        }, 10);
      }
    }
  };

  function changeDropdownValue(type, e) {
    if (type == "step_coaching_users") {
      var values = [];
      const matched_value=usergroups.filter(group => e.some(event => event.id === group.id)).map(group => group)
      e.map((prop, i) => {
        // var data = prop.id;
        // var data = { id: prop.id};
        var data = prop.id
        values.push(data);
      });
      var value =  values;
    } else {
      if (e) {
        var value = e.id;
      } else {
        var value = "";
      }
    }
    // setFormData(e.target.value)
    setFormState((formData) => ({
      ...formData,
      [type]: value,
    }));
  }

  function getSelectedItem(
    id,
    data = [],
    multiple = "",
    label = "",
    is_inputvalue = ""
  ) {
    if (multiple) {
      var items = [];
      id = typeof id === "string" ? id.split(",") : id;
      id.map((sid, i) => {
        const item = data.find((opt) => {
          if (label) {
            if (opt.label == sid) return opt;
          } else {
            if (opt.id == sid) return opt;
          }
        });
        if (item) {
          items.push(item);
        }
      });
      return items;
    } else {
      const item = data.find((opt) => {
        if (label) {
          if (opt.label == id) return opt;
        } else {
          if (opt.id == id) return opt;
        }
      });
      return item || null;
    }
  }

  const handleSubmitStep = async (event) => {
    var pdf_required = "False";
    if (formState.pdf_required && formState.pdf_required == "True") {
      pdf_required = "True";
    }
    var newformData = {
      step_name: formState.step_name,
      description: formState.description,
      // pdf_required: pdf_required,
      // step_message: "",
      user_group:
        typeof formState.category === "string"
          ? formState?.category
          : typeof formState.category === "object"
          ? formState?.category?.join(",")
          : "",
      company_name: formState.company_name,
    };
    const formData = new FormData();
    formData.append("step_name", formState.step_name);
    formData.append("description", formState.description);
    // formData.append("pdf_required", pdf_required);
    formData.append("company_name", formState.company_name);
    // formData.append('user_raised', 'test');
    // formData.append("step_message", "");
    // user_raised": username
    formData.append("step_coaching_users", "hello");
    formData.append("user_groups", formState.user_groups);
    var response = "";
    if (mode === "edit") {
      const renderedItems = formState.step_coaching_users.map((item) => (
          {id:item}
      ));
       
      // coaching_array.id.id==undefined?{id :formdata.coaching_action_group}:coaching_array.id

      formState["step_coaching_users"]=formState.step_coaching_users.map(t=>t.id==undefined)? renderedItems:formState.step_coaching_users.map(t=>t.id)
      var newformData = formState;
      newformData.id = data;

   
      // newformData["step_coaching_users"]=coaching_array.id.id==undefined?{id :formState.step_coaching_users[0]}:coaching_array.id


      // await formData.append("id", eventid);
      response = await putDataFromApi(
        coachingcreateWorkFlow ,
        newformData
      );
    } else {
      let newformData = formState;
    
      // newformData.step_coaching_users = formState.user_groups;
      // formState.user_groups=""
      response = await postDataFromApi(coachingcreateWorkFlow, newformData);

      // setalertMessage("Event Workflow steps Added successfully");
    }
    if (response && response.status == 200) {
      if (eventid) {
        setalertMessage("Event Workflow steps Updated successfully");
      } else {
        setalertMessage("Event Workflow steps Added successfully");
      }

      setalert(true);
      setalertType("success");
      setsamePage(true);
      navigate("/coaching_eventDesigner");
      onClose();
      // getworkflowStepList();
      // if (editmore) {
      //   var index = customSteps
      //     .map((x) => {
      //       return x.id;
      //     })
      //     .indexOf(editmoreId);
      //   customSteps[index] = response.data;
      // } else {
      //   // customSteps.push(response.data)
      //   customSteps.splice(selectedIndex, 0, response.data);
      //   //customSteps.insert(selectedIndex, response.data)
      // }
    } else {
      setalertMessage("error...");
      setalert(true);
      setalertType("error");
      setsamePage(true);
      // getworkflowStepList();
    }
  };

  function formdataValueChange(e, name = "") {
    var value = "";
    var eventname = "";
    if (name) {
      eventname = name;
      value = e;
    } else {
      if (e.target.type == "checkbox") {
        value = e.target.checked ? true : false;
      } else {
        value = e.target.value.trimStart();
      }
      eventname = e.target.name;
    }
    setFormState((formData) => ({
      ...formData,
      [eventname]: value,
    }));
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormState({ ...formState, [name]: value });
  // };

  // const handleSubmitClick = () => {
  //   handleSubmit(formState, mode);
  //   onClose();
  // };

  return (
    <>
      <AlertMessage
        alert={alert}
        alertMessage={alertMessage}
        confirm={confirm}
        alertType={alertType}
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        // aria-describedby="modal-modal-description"
        aria-labelledby="form-dialog-title"
      >
        <Box className="main-box grey-bg p-0 m-0">
          <MuiDialogTitle id="form-dialog-title" onClose={onClose}>
            {mode === "add" ? "Add" : mode === "edit" ? "Edit" : "View"}{" "}
           Coaching Step
            <img
              src={CloseBtn}
              className="discard-close-pointer"
              onClick={onClose}
            />
          </MuiDialogTitle>
          <DialogContent>
            <Grid item md={12} className=" p-0">
              <ValidatorForm
                onSubmit={handleSubmitStep}
                onError={() => null}
                className="p-0"
              >
                <Box style={{ backgroundColor: "white", padding: "10px" }}>
                  <Grid spacing={1} className="search-form-main p-0">
                    <Grid item md={12} className="user-add-block">
                      <div className="no-border-fieldset">
                        <Grid
                          container
                          spacing={1}
                          className="search-form-main "
                        >
                          <Grid item md={12} className="user-add-block">
                            <label>
                              Coaching Step Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              className="search-email gm-t-10 custom-input dark-grey-bg dark-grey-color"
                              id="size-small"
                              size="small"
                              placeholder="Coaching Step Name"
                              name="step_name"
                              value={formState.step_name || ""}
                              onChange={(e) => formdataValueChange(e)}
                              disabled={veiwMode}
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                            />
                          </Grid>
                          <Grid item md={12} className="user-add-block ">
                            <label>Coaching Step Description </label>
                            {/* className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none" */}

                            <TextField
                              size="small"
                              className="search-email gm-t-10 custom-input dark-grey-bg dark-grey-color"
                              type="text"
                              name="description"
                              id="description"
                              disabled={veiwMode}
                              placeholder="Coaching Step Description"
                              value={formState.description || ""}
                              onChange={(e) => formdataValueChange(e)}
                            />
                          </Grid>
                          <Grid item md={12} className="user-add-block ">
                            <label>
                              Coaching Step Users{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {is_edit_loaded && (
                              <AutoComplete
                                className="dropdown gm-t-10 w-100 mar-b-0 "
                                fullWidth
                                multiple
                                defaultValue={getSelectedItem(
                                  formData.step_coaching_users,
                                  usergroupsOpt,
                                  1
                                )}
                                // defaultValue={formdata.user_groups.map(item=>item.name)}
                                onChange={(event, value) =>
                                  changeDropdownValue("step_coaching_users", value)
                                }
                                options={usergroupsOpt}
                                disabled={veiwMode}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    {...params}
                                    className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                                    label="Select"
                                    name="step_coaching_users"
                                    placeholder=" Select Coaching Step Users"
                                    value={formState.step_coaching_users}
                                    // validators={["required"]}
                                    errorMessages={["This field is required"]}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <Grid item md={12} className="user-add-block">
                            <label>
                              Company <span style={{ color: "red" }}>*</span>
                            </label>
                            {is_edit_loaded && (
                              <AutoComplete
                                // disabled={isViewEnable ? formdata.role === 'iCAM Admin' || formdata.role === 'iCAM User' : ""}
                                className="dropdown gm-t-10 w-100 mar-b-0 "
                                fullWidth
                                options={companyOptions}
                                defaultValue={getSelectedItem(
                                  formState.company_name,
                                  companyOptions,
                                  "",
                                  1
                                )}
                                value={{
                                  label: formState.company_name,
                                  id: formState.company_name,
                                }}
                                // getOptionLabel={(option) => option.label}
                                onChange={(event, value) =>
                                  changeDropdownValue("company_name", value)
                                }
                                disabled={veiwMode}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="search-dropdown custom-input border-none"
                                    label="Company"
                                    name="company_name"
                                    placeholder="Company"
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    value={formState.company_name}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          {/* <Grid item direction="row">
                        <label className="">Event PDF Required</label>
                        <Grid className="">
                          <RadioGroup
                            row
                            aria-label="nameRadio"
                            name="pdf_required"
                            // type="checkbox"
                            className="group"
                            style={{ display: "inline" }}
                            disabled={veiwMode}
                            selectedValue={formdata.pdf_required}
                            onChange={(e) => formdataValueChange(e, 'pdf_required')}

                          >
                            <FormControlLabel
                              value="True"
                              disabled={veiwMode}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="False"
                              disabled={veiwMode}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                          <RadioGroup name="nameRadio" className="escalate-radio mt-10 mid-radio" value={formdata.nameRadio || ''} >
                                                <div className="">
                                                    <Radio className="radio-spc" name="nameRadio" value="true" onChange={(e) => formdataValueChange(e)} />Yes
                                                </div>
                                                <div className="workflow-radio">
                                                    <Radio value="false" name="nameRadio" onChange={(e) => formdataValueChange(e)} />No
                                                </div>
                                            </RadioGroup>
                        </Grid>
                      </Grid>*/}
                        </Grid>

                        <Grid item md={3} className="">
                          {!veiwMode && (
                            <Stack direction="row" spacing={3}>
                              <Button
                                type="button home-button"
                                variant="contained"
                                // {editmore ? "Update" : "Add"}
                                style={{ background: "#2E386B" }}
                              >
                                Save
                              </Button>
                              <Button
                                // type="button contact-button"
                                variant="contained"
                                style={{
                                  background: "#B8B1B1",
                                  color: "black",
                                }}
                                onClick={onClose}
                                // onClick={() => navigate("/event_designer")}
                              >
                                Back
                              </Button>
                            </Stack>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </ValidatorForm>
            </Grid>
          </DialogContent>
          {/* <DialogActions>
        {mode !== 'view' && <Button >{mode === 'add' ? 'Add' : 'Save'}</Button>}
        <Button onClick={onClose}>Close</Button>
      </DialogActions> */}
        </Box>
      </Dialog>
    </>
  );
};

export default DialogForm;
